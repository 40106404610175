@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.55;
}

h1 {
  font-size: 24px;
  font-weight: 700;
}
h1 + * {
  margin-top: 3px;
}

h2 {
  font-size: 16px;
  font-weight: 700;
}
h2 + * {
  margin-top: 20px;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  font-weight: 500;
}

.-color-1 {
  color: #2e416d;
}

a {
  text-decoration: none;
}

::selection {
  background: rgba(46, 65, 109, 0.8);
  color: white;
}

.nav {
  position: fixed;
  top: 0;
  padding: 55px 50px;
  width: 270px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2e416d;
}
.nav__logo {
  display: block;
  margin-bottom: 70px;
}
.nav__logo img {
  display: block;
  margin: auto;
  width: 130px;
}
.nav__logout {
  position: relative;
  z-index: 1;
  display: flex;
  margin-top: 55px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 1720px) {
  .nav__logout {
    font-size: 16px;
  }
}
.nav__logout img {
  width: 26px;
  margin-left: 6px;
  margin-right: 24px;
}
.nav hr {
  opacity: 0.8;
  margin-top: 40px;
  margin-bottom: 40px;
}
.nav::before {
  content: "";
  position: absolute;
  bottom: 138px;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/logout-shape.svg");
  background-position: bottom;
}
.nav::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 140px;
  background-color: #46567b;
}

.header {
  position: relative;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  margin-bottom: 50px;
  background-color: #2e416d;
}
.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header::after {
  content: "";
  position: absolute;
  top: calc(100% - 2px);
  pointer-events: none;
  height: 12px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/shape-header.svg");
  background-position: bottom;
}
@media (min-width: 420px) {
  .header::after {
    display: none;
  }
}
.header__logo img {
  height: 32px;
  width: auto;
}
.header__title {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: white;
}
.header__button {
  width: 37px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/icon-back.svg");
}

section + section {
  margin-top: 40px;
}
section:last-of-type {
  padding-bottom: 60px;
}

.container {
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 470px;
}

.form__control label {
  display: block;
  margin-bottom: 8px;
  color: #2e416d;
  font-weight: 500;
  font-size: 14px;
}
.form__control input,
.form__control select {
  width: 100%;
  border-radius: 4px;
  border: none;
  height: 55px;
  padding: 0 18px;
  appearance: none;
  -webkit-appearance: none;
  background-color: #efeff6;
  outline: none;
}
.form__control select {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/arrow-down.svg");
  background-size: 12px;
  background-position: center right 20px;
  cursor: pointer;
}
.form__control.-error input {
  background-color: rgba(239, 68, 92, 0.1);
}
.form__control + .button {
  margin-top: 30px;
}
.form__control + .form__control {
  margin-top: 30px;
}
.form__error {
  margin-top: 10px;
  font-size: 12px;
  color: #ef445c;
}

.toggle label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.toggle__switch {
  position: relative;
  transition: background-color 300ms ease-out;
  width: 50px;
  height: 26px;
  border-radius: 20px;
  background-color: #efeff6;
}
.toggle__check {
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 300ms ease-out;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: white;
}
.toggle input {
  position: absolute;
  width: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.toggle input:checked + label .toggle__switch {
  background-color: #2e416d;
}
.toggle input:checked + label .toggle__check {
  left: 28px;
}

.file {
  display: block;
  margin-top: 25px;
}
.file .button {
  margin-right: 10px;
}
.file input[type="file"] {
  text-indent: -128px;
}
@media (max-width: 1720px) {
  .file input[type="file"] {
    text-indent: -115px;
  }
}

.search {
  border: none;
  border-bottom: 1px solid #2e416d;
  width: 50%;
  padding: 0 0 12px 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("img/zoeken.svg");
  background-size: 16px;
  background-position: top 3px left;
  outline: none;
}
.search::placeholder {
  color: #2e416d;
  opacity: 0.4;
}

.menu {
  width: 100%;
}
.menu__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: border-color 300ms ease-out, background-color 300ms ease-out;
  width: 36px;
  height: 36px;
  border: 1px solid white;
  border-radius: 100%;
  margin-right: 20px;
}
@media (max-width: 1720px) {
  .menu__icon {
    width: 32px;
    height: 32px;
  }
}
.menu__icon svg {
  height: 40%;
  width: auto;
  max-width: 14px;
  fill: white;
}
.menu__item {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 1720px) {
  .menu__item {
    font-size: 16px;
  }
}
.menu__item + .menu__item {
  margin-top: 20px;
}
.menu__item:hover .menu__icon,
.menu__item.-active .menu__icon {
  border-color: #b7e3ea;
  background-color: #b7e3ea;
}
.menu__item:hover .menu__icon svg,
.menu__item.-active .menu__icon svg {
  fill: #2e416d;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 300ms ease-out, background-color 300ms ease-out;
  border-radius: 28px;
  padding: 8px 24px;
  box-shadow: 0 5px 10px 0 rgba(50, 67, 109, 0.1);
  border: none;
  background-color: #6dd295;
  cursor: pointer;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}
.button:hover {
  box-shadow: 0 15px 20px 0 rgba(50, 67, 109, 0.3);
  background-color: #2e416d;
}
.button.-secondary {
  background-color: transparent;
  border: 2px solid #2e416d;
  box-shadow: none;
  color: #2e416d;
}
.button.-secondary:hover {
  background-color: #2e416d;
  color: white;
}

.button.-tertiary {
  background-color: transparent;
  box-shadow: none;
  color: #2e416d;
  float: right;
  padding: 8px 8px;
}
.button.-tertiary:hover {
  background-color: #2e416d;
  color: white;
  float: right;
}

.progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: #455A64;
  padding-left: 0px;
  margin-top: 30px;
  text-align: center;

}

.progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 33%;
  height: 52px;
  /* float: left; */
  position: relative;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 50px;
}
.progressbar li:hover {
  cursor: pointer;

}

.progressbar li:last-child {
  margin-bottom: 120px;

}


.progressbar .step0:before {
  font-family: FontAwesome;
  content: "\f10c";
  color: #fff
}

.progressbar li:before {
  width: 40px;
  height: 40px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  background: #C5CAE9;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
  position: absolute;
  left: 200px;
  top:-10px;
}

.progressbar li:not(:last-child):after {
  content: '';
  width: 12px;
  height: 72px;
  background: #C5CAE9;
  position: absolute;
  left: 214px;
  top: 28px;
  z-index: -1
}


/* .progressbar li:nth-child(2):after,
.progressbar li:nth-child(3):after {
  left: -50%
} */

.progressbar li.active:before,
.progressbar li.active:after {
  background: #2e416d
}
/* .progressbar li.active {
  background: #2e416d
} */

.progressbar li.active:before {
  font-family: FontAwesome;
  content: "\f00c"
}

.event__card {
  width: 100%;
  padding: 15px;
  position: absolute;
  display: flex;
  margin-left: 0px;
  margin-top: 0px;
  left: 0;
  top:0;
  flex-wrap: wrap;
  border-radius: 6px;
  transition: opacity 300ms ease-out;
  background-color: #efeff6;
}
.event__card__table {
  width: 100%;
}

.event__card__image {
  width: 100%;
  /* position: "relative"; */
}

/* .button + .button {
  margin-top: 12px;
} */

/* .table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 30px;
  text-align: left;
}
.table tbody tr td {
  overflow: hidden;
  transition: background-color 300ms ease-out, box-shadow 300ms ease-out 0ms;
  padding: 15px 0 15px 20px;
  text-overflow: ellipsis;
  color: #2e416d;
}
.table tbody tr td:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-weight: 500;
}
.table tbody tr td:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.table tbody tr td:last-of-type {
  padding-right: 20px;
}
.table tbody tr:nth-child(odd) td {
  background-color: #efeff6;
} */


.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: auto;
}
.table thead th {
  border-bottom: 1px solid #2e416d;
  padding: 0 0 20px 20px;
  color: #2e416d;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
}
.table tbody tr td {
  transition: background-color 300ms ease-out, box-shadow 300ms ease-out 0ms;
  padding: 25px 0 25px 20px;
  border-top: 1px solid rgba(46, 65, 109, 0.25);
}
.table tbody tr td:first-of-type {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table tbody tr td:last-of-type {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
/* .table tbody tr td img, */
.table tbody tr td svg {
  height: 12px;
  float: right;
}
.table tbody tr td:last-of-type {
  padding-right: 20px;
}
.table tbody tr:last-of-type td {
  border-bottom: 1px solid rgba(46, 65, 109, 0.25);
}
.table__filter {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
.table__filter input {
  flex-grow: 1;
  border: none;
  margin-right: 10px;
  border-radius: 6px;
  border-radius: 28px 6px 6px 28px;
  padding: 15px 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #efeff6;
  background-image: url("img/zoeken.svg");
  background-size: 15px;
  background-position: center left 22px;
  outline: none;
}
.table__filter input::placeholder {
  color: #2e416d;
  opacity: 0.4;
}
.table__filter .button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.table__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.react-bootstrap-table-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.table.-clickable {
  table-layout: auto;
}
.table.-clickable tr td {
  cursor: pointer;
}
.table.-clickable tr:hover td {
  transition: background-color 300ms ease-out, box-shadow 300ms ease-out 200ms;
  border-top: 1px solid transparent;
  box-shadow: 0 15px 20px 0 rgba(50, 67, 109, 0.3);
  background-color: #2e416d;
  color: white;
}
.table.-clickable tr:hover td svg {
  fill: white;
}
.table + p {
  padding-left: 20px;
  margin-top: 25px;
}
.table + .button {
  margin-top: 20px;
}
.table.-small {
  margin-top: 20px;
}
.table.-small td {
  padding: 12px 0;
  text-transform:capitalize;
}
.table.-small td:first-child {
  font-weight: 500;
}
.table.-medium {
  margin-top: 40px;
}
.table.-medium th {
  padding-bottom: 17px;
}
.table.-medium td {
  padding-top: 17px;
  padding-bottom: 17px;
}
.table.-minimal td {
  padding: 7px 0;
  border-bottom: none !important;
  border-top: none;
}

.model {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: scroll;
  width: 100%;
  height: 100%;
  padding: 130px 25px 60px;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.model__container {
  position: relative;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 0;
  transform: translateY(40px);
  width: 100%;
  max-width: calc(470px - 50px);
  margin: auto;
  padding: 35px 30px;
  border-radius: 6px;
  background-color: white;
}
.model__cross {
  position: absolute;
  right: 30px;
  top: 38px;
  width: 15px;
  cursor: pointer;
}
.model__divider {
  margin: 40px 0 20px;
  width: 100%;
  height: 1px;
  background-color: #2e416d;
}
.model__title {
  width: 100%;
  padding: 0 40px;
  text-align: center;
}
.model.-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
.model.-active .model__container {
  opacity: 1;
  transform: translateY(0px);
}


.blocks {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.blocks .block {
  width: calc(50% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 18px 20px;
  border-radius: 4px;
  background-color: #e6e6ef;
  color: #2e416d;
}
.blocks .block__title {
  display: block;
  font-size: 12px;
}
.blocks .block__value {
  font-weight: 500;
}
.blocks .block.-wide {
  width: calc(100% - 10px);
}

.hamburger {
  position: relative;
  width: 22px;
  height: 18px;
  cursor: pointer;
}
.hamburger span {
  position: absolute;
  right: 0;
  display: inline-block;
  transition: 300ms ease-out;
  border-radius: 2px;
  width: 100%;
  height: 2px;
  background-color: white;
}
.hamburger span:nth-of-type(1) {
  top: 0;
}
.hamburger span:nth-of-type(2) {
  top: calc(50% - 1px);
  width: 18px;
}
.hamburger span:nth-of-type(3) {
  bottom: 0;
}
.hamburger.-active span {
  background-color: white;
}
.hamburger.-active span:nth-of-type(1) {
  transform: rotate(-45deg) translateY(0px);
  top: calc(50% - 1px);
}
.hamburger.-active span:nth-of-type(2) {
  opacity: 0;
}
.hamburger.-active span:nth-of-type(3) {
  transform: rotate(45deg) translateY(0px);
  bottom: calc(50% - 1px);
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 100%;
  right: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
  transition: 300ms ease-out;
  background-color: #2e416d;
}
.mobile-menu .container {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 40px;
}
.mobile-menu.-active {
  left: 0;
}

.button.-wide {
  width: 100%;
  margin-bottom: 5px;
}
.button.-wide.-sec {
  background-color:  #2e416d;;
}
.button.-wide.-cancel {
  background-color:  #6d2e2e;
}
.link {
  display: block;
  transition: color 300ms ease-out, border-color 300ms ease-out;
  width: fit-content;
  width: -moz-fit-content;
  margin: 20px auto 0;
  border-bottom: 1px solid currentColor;
  color: #2e416d;
  font-size: 14px;
}
.link:hover {
  color: #6dd295;
}

h1 {
  font-size: 28px;
  font-weight: 700;
}
@media (max-width: 1720px) {
  h1 {
    font-size: 24px;
  }
}
h1 + * {
  margin-top: 10px;
}
h1 + .form__control {
  margin-top: 25px;
}

.spinning {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

/*# sourceMappingURL=style.css.map */
.btn-lg, .btn-group-lg > .btn {

  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {

  border-radius: 0.2rem;
}


.dropdown__assign.-open {
  border-bottom: 1px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dropdown__assign.-open .dropdown__list__assign {
  padding-bottom: 10px;
  border-top: 1px solid transparent;
}
.dropdown__assign.-open .dropdown__option__assign label {
  display: block;
}
.dropdown__assign.-open::after {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}



.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}


.button.active{
  background-color: #ffbebe;
}

.dropdown__list__assign {
  position: absolute;
  top: 100%;
  left: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  background-color: #efeff6;
  width: 380px;
}

.dropdown__assign::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  display: block;
  transition: transform 300ms ease-out;
  margin: auto;
  width: 8px;
  height: 8px;
  background-image: url("img/arrow-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dropdown__option__assign label {
  display: none;
  padding: 0 15px;
  margin-top: 5px;
  cursor: pointer;
  line-height: 1;
  font-size: 18px;
}
.dropdown__option__assign input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

/* .dropdown__option__assign input:checked + label {
  position: absolute;
  top: -29px;
  overflow: hidden;
  display: block;
  max-width: calc(100% - 10px);
  pointer-events: none;
  font-weight: 500;
  text-overflow: ellipsis;
} */

.dropdown__list {
  position: absolute;
  top: 100%;
  left: -1px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid transparent;
  width: calc(100% + 2px);
}
.dropdown__option label {
  display: none;
  padding: 0 15px;
  margin-top: 5px;
  cursor: pointer;
  line-height: 1;
  font-size: 14px;
}
.dropdown__option input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
/* .dropdown__option input:checked + label {
  position: absolute;
  top: -29px;
  overflow: hidden;
  display: block;
  max-width: calc(100% - 10px);
  pointer-events: none;
  font-weight: 500;
  text-overflow: ellipsis;
} */

.dropdown__assign {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: none;
  height: 55px;
  padding: 10px 18px;
  /* appearance: none; */
  background-color: #efeff6;
  cursor: pointer;
  font-size: 20px;


}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.board {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}
.board__holder {
  box-shadow: 0 10px 20px 0 rgba(50, 67, 109, 0.4);
  width:100%;
  padding: 0px;
  border-radius: 6px;
  margin-right: 0px;
}

.board__holder_row {
  margin: 0px;
  margin-right: 0px;
  padding: 5px;
  background-image: linear-gradient(0deg, #ffffff 2%, #32436d 2%, #32436d 48%, #ffffff 48%, #ffffff 53%, #32436d 50%, #32436d 97%);
}

.board__holder_column {
  margin: 0px;
  margin-right: 0px;
  padding: 5px;
  background-image: linear-gradient(90deg, #ffffff 0.5%, #32436d 0.5%, #32436d 9%, #ffffff 9%, #ffffff 10.5556%, #32436d 10.5556%, #32436d 19.0556%, #ffffff 19.0556%, #ffffff 20.6111%, #32436d 20.6111%, #32436d 29.1111%, #ffffff 29.1111%, #ffffff 30.6666%, #32436d 30.6666%,
  #32436d 39.1666%, #ffffff 39.1666%, #ffffff 40.7222%, #32436d 40.7222%, #32436d 49.2222%, #ffffff 49.2222%, #ffffff 50.7777%, #32436d 50.7777%, #32436d 59.2777%, #ffffff 59.2777%, #ffffff 60.8333%, #32436d 60.8333%,
  #32436d 69.3333%, #ffffff 69.3333%, #ffffff 70.8888%, #32436d 70.8888%, #32436d 79.3888%, #ffffff 79.3888%, #ffffff 80.9444%, #32436d 80.9444%, #32436d 89.4444%, #ffffff 89.4444%, #ffffff 90.9999%, #32436d 90.9999%, #32436d 99.5%, #ffffff 99.5%)}

@media (max-width: 1720px) {
  .board__holder {
    padding: 5px;
    margin-right: 3px;
    margin-left: 3px;

  }
}
.board__row {
  display: flex;
  justify-content: space-between;
  background-color: #32436d;
  padding: 2px;

}
.board__row + .board__row {
  margin-top: 12px;
}
.board__pin {
  position: relative;
  transition: border-color 300ms ease-out;
  border-radius: 20%;
  width: calc(10% - 5px);
  padding-top: calc(10% - 16px);
  height: 0;
  border: 2px solid #efeff6;
  background-color: #efeff6;
  cursor: pointer;
}
.board__pin.-green {
  border-color: #6dd295;
  background-color: #6dd295;
  color: white;
}

.board__pin.-error {
  border-color: #ef445c;
  background-color: #ef445c;
  color: white;
}

.board__pin:hover {
  border: 2px solid rgba(46, 65, 109, 0.4);
}
.board__pin.-active {
  border: 2px solid #2e416d;
}
.board__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
}
@media (max-width: 1720px) {
  .board__number {
    font-size: 16px;
  }
}
.board__card {
  width: 33%;
  padding: 50px;
  border-radius: 6px;
  transition: opacity 300ms ease-out;
  background-color: #efeff6;
}
@media (max-width: 1720px) {
  .board__card {
    padding: 40px;
  }
}
.board__card.-active {
  opacity: 1;
}

.overview__item {
  position: relative;
  overflow: hidden;
  width: 100 %;
  padding: 40px;
  border-radius: 4px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: #efeff6;
}

.overview__item *:not(.button) {
  color: #2e416d;
}
.overview__item .button {
  margin-top: 35px;
  width: 100%;
}
.overview__title {
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 10px);
  white-space: nowrap;
}
.button img {
  display: block;
  width: auto;
  height: 16px;
  margin-right: 15px;
}
.button svg {
  display: block;
  width: auto;
  height: 16px;
  margin-right: 15px;
}

.button.-red {
  background-color: #ef445c;
  width: 100%;
  margin-bottom: 5px;
}
.button.-red:hover {
  box-shadow: 0 15px 20px 0 rgba(50, 67, 109, 0.3);
  background-color: #2e416d;
}

.board__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}
.parking__row {
  display: flex;
/*  justify-content: space-between;
  /* background-color: #32436d; */
  padding: 2px;

}

.parking__pin {
  position: relative;
  transition: border-color 300ms ease-out;
  width: calc(10%);
  padding-top: calc(5%);
  margin-left: calc(3% - 8px);
  margin-right: 0;
  height: 0;
  border: 2px solid #909090;
  border-left-style: none;
  background-color: #efeff6;
  cursor: pointer;
}

.parking__holder {
  box-shadow: 0 10px 20px 0 rgba(50, 67, 109, 0.4);
  width: 100%;
  border-radius: 6px;
}

.parking__pin:nth-child(2n) {
  /* margin-right: calc(5% - 16px); */
  border-right-style: none;
  margin-left:0;
}

.parking__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.parking__pin.-green {
  border-color: #6dd295;
  background-color: #6dd295;
  color: white;
}

.parking__pin.-error {
  border-color: #ef445c;
  background-color: #ef445c;
  color: white;
}

.parking__pin:hover {
  border: 2px solid #ff220066;
}
.parking__pin.-active {
  border: 2px solid #2e416d;
}

.username__text {
  width: 200px;
  word-wrap: break-word;
}

.zone__row {
  display: flex;
/*  justify-content: space-between;
  /* background-color: #32436d; */
  padding: 2px;

}

.zone__pin {
  position: relative;
  transition: border-color 300ms ease-out;
  width: calc(10%);
  padding-top: calc(5%);
  margin-left: calc(1% - 4px);
  margin-right:  calc(1% - 4px);
  height: 0;
  border: 2px solid #909090;
  /* border-left-style: none; */
  background-color: #efeff6;
  cursor: pointer;
}

.zone__title {
  position: relative;
  width: calc(10%);
  padding-top: calc(8%);
  margin-left: calc(1% - 4px);
  margin-right:  calc(1% - 4px);
  height: 0;
  /* border-left-style: none; */
 /* background-color: #efeff6;*/
  cursor: pointer;
  word-break: break-all
}

.zone__column_span {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group > .btn:hover, .btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus {
  z-index: 1;
}
.btn-group-vertical>.btn:hover, .btn-group>.btn:hover {
  z-index: 1;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.zone__number {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.zone__pin.-green {
  border-color: #6dd295;
  background-color: #6dd295;
  color: white;
}

.zone__pin.-error {
  border-color: #ef445c;
  background-color: #ef445c;
  color: white;
}

.zone__pin:hover {
  border: 2px solid #ff220066;
}
.zone__pin.-active {
  border: 2px solid #2e416d;
}

.btn-group-lg>.btn, .btn-lg {
  padding: 0.5rem 1rem;
  padding-top: 0.5rem;
  padding-right: 0.85rem;
  padding-bottom: 0.5rem;
  padding-left: 0.85rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.QR_div {
  width:50%;
  margin-right:auto;
  margin-left: auto;
  margin-top:16px;
  margin-bottom:16px;

  display: flex;
align-items: center;
justify-content: center;
}

.button.-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #beffd8;
}